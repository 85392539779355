import "./WhatWeDo.scss"
import {S} from "../../assets/Strings";
import { title } from "process";

export function WhatWeDo() {
    const services = [
        {
            title: "Submit Your Existing Ads",
            text: "Simply share your existing social, display, or video ads— no need to create new assets.",
            image: "services1.png"
        },
        {
            title: "We Do the Rest",
            text: "Our team transforms your ads into engaging voice content, leveraging our GenAI tools, expert studio, and curated voice talent network.",
            image: "services2.png"
        },
        {
            title: "Optimize and Scale",
            text: "We optimize your voice ads for maximum impact, ensuring seamless distribution across podcasts, streaming platforms, and smart devices.",
            image: "services3.png"
        },
        {
            title: "Amplify Your Reach",
            text: "Reach your audience wherever they listen, with scalable, data-driven campaigns that deliver measurable results.",
            image: "services4.png"
        }
    ]

    return <div className="what-we-do">
                <div className="title h2">{S.services.title}</div>
                {services.map((s, i) => <div key={`service-${i}`} className={`item ${i%2 === 0 ? "even" : "odd"}`}>
                    <div className="body-mobile">{s.text}</div>
                    <div className="image">
                        <img src={`/services/${s.image}`}/>
                    </div>
                    <div className="text">
                        <div className="title">
                            <div className="number">{i+1}</div>
                            <div className="desc">{s.title}</div>
                        </div>
                        <div className="body">{s.text}</div>
                    </div>
                </div>)}
        </div>
}