import './Blog.scss'
import {ReactComponent as Open} from "../assets/open.svg"
import { Link } from 'react-router-dom'

export const Blog = () => {
    const caseStudies = [
        {
            name: 'aarp',
            title: 'AARP - Audience Development',
            image: 'aarp.jpg',
            link: 'https://docs.google.com/document/d/1UQLNHlXzm0n4iXwL93G0DucbqBFWNzybOn2olRwp5-k/edit?tab=t.0'
        },
        {
            name: 'economist',
            title: 'The Economist - Converting Content',
            image: 'economist.jpg',
            link: 'https://docs.google.com/document/d/1Ywh73PNC19DQcBGALJRnnZ_ArJyH1TAvnQ2XzbmIm_M/edit?tab=t.0#heading=h.58kynclvs2bv'
        },
        {
            name: 'turbotax',
            title: 'TurboTax - Thought Leadership',
            image: 'turbotax.jpg',
            link: 'https://docs.google.com/document/d/1nWuTU1CczY35f5DonL-hP-NhikC-6-K286rsOBBqZcA/edit?tab=t.0'
        }
    ]

    return <div className="blog">
        <div className="title h1">Case Studies</div>
        {caseStudies.map(cs => <Link className='case-study' key={cs.name} to={cs.link} target="_blank">
            <img src={'/case-studies/'+cs.image} alt='case study'/>
            <div className='subtitle h2'>{cs.title}<Open/></div>
        </Link>)}
    </div>
}