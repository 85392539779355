import "./Home.scss"
import {Footer} from "../components/Footer";
import {Partners} from "../components/home/Partners";
import React, {RefObject, useEffect, useRef} from "react";
import {WorkTogether} from "../components/home/WorkTogether";
import {HomeHero} from "../components/publishers/HomeHero";
import {Demos} from "../components/home/Demos";
import { WhatWeDo } from "../components/home/WhatWeDo";

export const Home = () => {
    const refs = new Map<string, RefObject<HTMLDivElement>>([
        ["salesForce", useRef(null)],
        ["winnebago", useRef(null)],
        ["brooks", useRef(null)],
        ["liquidiv", useRef(null)],
        ["transporttopics", useRef(null)],
        ["aarp", useRef(null)],
        ["work", useRef(null)]
    ])

    useEffect(() => {
        const r = refs.get("work")
        if (window.location.pathname === '/work-together' && r?.current)
            r.current.scrollIntoView()
    }, [refs.get("work")]);

return <div className="advertisers">
        <HomeHero/>
        <Partners/>
        <WhatWeDo/>
        <Demos refs={refs}/>
        <WorkTogether refs={refs}/>
        <Footer/>
    </div>
}
